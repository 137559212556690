<template>
  <div class="template-residential-template-info" v-html="description"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    description() {
      return this.$path('value.description')
    },
    title() {
      return this.$path('value.name')
    },
  },
  components: {},
}
</script>

<style lang="scss">
.template-residential-template-info {
  line-height: 1.5;

  & > p:first-child {
    margin-top: 0;
  }
}
</style>
